// export const theme = (selectTheme) => ({
//   ...selectTheme,
//   borderRadius: 50,
//   colors: {
//     ...selectTheme.colors,
//     primary25: "hotpink",
//     primary: "black",
//   },
// });
export const theme = (theme) => theme;

const HEIGHT = 72;
const FONT_SIZE = 18;
const BORDER_RADIUS = `${HEIGHT / 2}px`;

export const styles = {
  container: (provided, state) => ({
    ...provided,
    cursor: "pointer",
    color: "#0a1d27",
    fontSize: 16,
    textAlign: "left",
    width: 580,
    maxWidth: "100%",
    margin: "0 auto",
  }),

  control: (provided, state) => {
    const topBottomPadding = `${(HEIGHT - FONT_SIZE) / 2}px`;

    return {
      ...provided,
      border: 0,
      borderRadius: state.menuIsOpen
        ? `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`
        : BORDER_RADIUS,
      padding: `${topBottomPadding} 30px ${topBottomPadding} 58px`,

      // search icon
      backgroundImage: `url("data:image/svg+xml;charset=utf8,${encodeURIComponent(
        '<svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path fill="#222" d="M2.401 6.993A4.6 4.6 0 016.996 2.4a4.6 4.6 0 014.596 4.593 4.6 4.6 0 01-4.596 4.593 4.6 4.6 0 01-4.595-4.593m15.247 8.959l-4.93-4.937a6.997 6.997 0 001.275-4.022C13.993 3.137 10.855 0 6.996 0 3.14 0 0 3.137 0 6.993c0 3.857 3.139 6.993 6.996 6.993 1.44 0 2.85-.449 4.023-1.275l4.932 4.937c.226.227.528.352.848.352.322 0 .622-.125.85-.351.226-.227.351-.528.351-.849 0-.32-.125-.622-.352-.848" /></svg>',
      )}")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "18px 18px",
      backgroundPosition: "25px 50%",

      // boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1), 0 -4px 11px hsla(0,0%,0%,0.1)",
      boxShadow: "0 0 0 1px hsla(0,0%,0%,0.1)",
      // boxShadow: "none", // remove border on focus

      transition: "none", // so border radius doesn't transition

      "&:hover": {
        border: 0,
      },
    };
  },

  menu: (provided, state) => ({
    ...provided,
    margin: 0,
    borderRadius: `0 0 ${BORDER_RADIUS} ${BORDER_RADIUS}`,
    // paddingBottom: "30px",
    overflow: "hidden", // for hover background color of option
    boxShadow: "0 1px 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",

    "&::after": {
      position: "absolute",
      top: 0,
      right: "24px",
      left: "24px",
      height: "1px",
      backgroundColor: "#a4aab2",
      content: "''",
    },
  }),

  groupHeading: (provided, state) => ({
    ...provided,
    padding: "0 40px",
    color: "#1c1041",
    fontSize: 20,
    fontWeight: 800,
    lineHeight: 1.3,
  }),

  option: (provided, state) => ({
    ...provided,
    padding: "8px 56px",
    lineHeight: 1,
  }),

  menuList: (provided, state) => ({
    ...provided,
    paddingBottom: "28px",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    fontSize: FONT_SIZE,
    fontWeight: 300,
    lineHeight: 1,
    overflow: "visible", // for when the text wraps
  }),

  input: (provided, state) => ({
    padding: 0,
    margin: 0,
    fontSize: FONT_SIZE,
    fontWeight: 300,
    lineHeight: 1,
    height: FONT_SIZE, // not sure why the height is 21px if height isn't specified.

    "&:focus": {
      outline: 0,
    },
  }),

  dropdownIndicator: (provided, state) => ({
    display: "none",
  }),

  indicatorSeparator: (provided, state) => ({
    display: "none",
  }),
};
