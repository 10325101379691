import React, { useContext, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as R from "ramda";

import { lookupStateAbbreviation } from "./states.js";
import { formatPhone } from "./format.js";
import { extractRichText, extractInfoTooltip } from "./contentful-extract.jsx";

const ContentfulDepartmentsContext = React.createContext();

export function useContentfulDepartments() {
  return useContext(ContentfulDepartmentsContext);
}

/**
 * Do this expensive processing in context so it only needs to be done once
 *
 * !!! This provider is actually being included in mulitple pages (index.js and
 * map.jsx...), so it has to run again after navigating between those pages.  To
 * avoid that, we're caching it on the window object.  We can't use
 * sessionStorage because there are React nodes in the data, so it can't be
 * serialized to JSON.
 *
 * TODO maybe a better approach would be to defer the extractRichText and
 * extractInfoTooltip until we actually render, ie, remove those calls from
 * extractFileMap and add them to the leaf React components
 */
export const ContentfulDepartmentsProvider = (props) => {
  const data = useStaticQuery(query);

  const allDepartments = useMemo(() => {
    const ALL_DEPARTMENTS_KEY = "___acountableNowAllDepartments___";

    // if the data is cached, use that
    // first, check for existance of window (it won't exist when server rendering)
    const cachedData =
      typeof window !== "undefined" && window[ALL_DEPARTMENTS_KEY];

    if (cachedData) {
      return cachedData;
    }
    // otherwise, do the expensive calculation and cache it
    else {
      const allDepartments = getAllDepartments(data);

      if (typeof window !== "undefined") {
        window[ALL_DEPARTMENTS_KEY] = allDepartments;
      }

      return allDepartments;
    }
  }, [data]);

  // const medford = R.find(R.propEq("ori", "MA0093000"), allDepartments);
  // const somerville = R.find(R.propEq("ori", "MA0093900"), allDepartments);

  return (
    <ContentfulDepartmentsContext.Provider value={allDepartments} {...props} />
  );
};

const getAllDepartments = (data) => {
  const dataFilesByOri = R.compose(
    extractFileMap,
    R.path(["allContentfulDataFile", "nodes"]),
  )(data);

  const foiaRejectionsByOri = R.compose(
    extractFileMap,
    R.path(["allContentfulFoiaRejection", "nodes"]),
  )(data);

  return R.compose(
    R.sortBy(R.prop("name")),
    R.reject(
      R.anyPass([propIsEmptyOrNil("stateName"), propIsEmptyOrNil("ori")]),
    ),
    R.map(
      R.applySpec({
        ori: R.prop("ori"),
        name: R.compose(capitalizeAll, R.prop("name")),
        latitude: R.path(["coordinates", "lat"]),
        longitude: R.path(["coordinates", "lon"]),
        stateAbbreviation: R.compose(lookupStateAbbreviation, R.prop("state")),
        stateName: R.prop("state"),
        content: R.compose(extractRichText, R.prop("content")),
        phone: R.compose(formatPhone, R.prop("phone")),
        email: R.prop("email"),
        dataFiles: R.compose(
          R.defaultTo([]),
          (ori) => dataFilesByOri[ori],
          R.prop("ori"),
        ),
        foiaRejections: R.compose(
          R.defaultTo([]),
          (ori) => foiaRejectionsByOri[ori],
          R.prop("ori"),
        ),
      }),
    ),
    R.path(["allContentfulDepartment", "nodes"]),
  )(data);
};

// extract data files and foiaRejection files
const extractFileMap = R.compose(
  R.groupBy(R.prop("ori")),
  R.sort(R.descend(R.prop("uploaded"))), // newer files first
  R.map(
    R.applySpec({
      ori: R.path(["department", "ori"]),
      file: R.path(["file", "file", "url"]), // url
      name: R.prop("name"),
      description: R.compose(extractRichText, R.prop("description")),
      uploaded: R.prop("uploaded"), // ISO date string
      infoTooltip: extractInfoTooltip,
    }),
  ),
);

const propIsEmptyOrNil = (prop) =>
  R.compose(R.anyPass([R.isEmpty, R.isNil]), R.prop(prop));

const capitalizeAll = R.compose(
  R.join(" "),
  R.map(R.replace(/^./, R.toUpper)),
  R.split(/\s+/),
  R.toLower,
);

// !! only PUBLIC data files!
const query = graphql`
  {
    allContentfulDataFile(filter: { status: { eq: "Public" } }) {
      nodes {
        id
        name
        description {
          raw
          references {
            ...Link
          }
        }
        department {
          ori
        }
        status
        file {
          file {
            url
          }
        }
        uploaded
        infoTooltip {
          label
          tooltipContent {
            raw
          }
        }
      }
    }
    allContentfulFoiaRejection {
      nodes {
        description {
          raw
          references {
            ...Link
          }
        }
        department {
          ori
        }
        file {
          file {
            url
          }
        }
        infoTooltip {
          label
          tooltipContent {
            raw
          }
        }
        name
        uploaded
      }
    }
    allContentfulDepartment {
      nodes {
        ori
        name
        coordinates {
          lat
          lon
        }
        state
        content {
          raw
          references {
            ...Link
          }
        }
        phone
        email
      }
    }
  }
`;
