import { useCallback, useMemo } from "react";
import * as R from "ramda";
import { string } from "prop-types";
import debounce from "lodash.debounce";
import { useStaticQuery, graphql } from "gatsby";

import { states } from "../../common/states.js";
import { createMatchAllQueryRegex } from "../../common/queryRegex.js";
import { useContentfulDepartments } from "../../common/contentfulDepartments.jsx";
import { styles, theme } from "./styles.js";

import AsyncSelect from "react-select/async";

import { navigateToMapEntity } from "../../common/mapUrl.js";

const propTypes = {
  placeholder: string,
};

const allStates = R.compose(
  (states) => ({
    label: "States",
    options: states,
  }),
  R.map(
    R.applySpec({
      id: R.prop("abbreviation"),
      label: R.prop("name"),
    }),
  ),
)(states);

const Search = (props) => {
  const { placeholder } = props;

  const allDepartmentsData = useContentfulDepartments();

  const contentfulData = useStaticQuery(graphql`
    {
      contentfulMapPage {
        searchPlaceholderText
      }
    }
  `);

  const searchPlaceholderText = R.path(
    ["contentfulMapPage", "searchPlaceholderText"],
    contentfulData,
  );

  const allDepartments = useMemo(
    () =>
      R.compose(
        R.sortBy(R.prop("label")),
        R.map(R.zipObj(["label", "options"])),
        R.toPairs,
        // map groups, then departments
        R.map(
          R.map(
            R.applySpec({
              id: R.prop("ori"),
              label: R.prop("name"),
            }),
          ),
        ),
        R.groupBy(R.prop("stateName")),
      )(allDepartmentsData),
    [allDepartmentsData],
  );

  // exhaustive-deps is complaining that it can't determine the inputs of the
  // function because it's debounced
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      const queryRegex = createMatchAllQueryRegex(inputValue);

      const statesGroupLabel = R.prop("label", allStates); // The word "States"

      const options = R.compose(
        R.map((group) =>
          R.over(
            R.lensProp("options"),
            R.compose(
              // always show all states, but only show the top ten of each state group
              // for performance reasons (can't render 20k depts)
              // show all if the inputValue matches the state
              R.when(
                () =>
                  group.label !== statesGroupLabel &&
                  // if the query doesn't matche the state label
                  !R.test(queryRegex, group.label),
                R.take(10),
              ),
              R.filter(
                R.compose(
                  R.test(queryRegex),
                  // add the state to the end of the dept label so queries for the state will match,
                  // eg, "Dallas Police Department" will become "Dallas Police Department Texas"
                  // so a query of "Dallas Texas" will match
                  (option) =>
                    group.label !== statesGroupLabel
                      ? `${option.label} ${group.label}` // add state to dept label
                      : option.label, // Don't add the group label to the states
                ),
              ),
            ),
            group,
          ),
        ),
        // only search the deptartments if we have a long-ish query (put them at the end)
        R.when(() => inputValue.length >= 3, R.concat(R.__, allDepartments)),
      )([allStates]);

      callback(options);
    }, 200),
    [allDepartments],
  );

  return (
    <AsyncSelect
      aria-label={placeholder || searchPlaceholderText}
      cacheOptions
      defaultOptions
      loadOptions={loadOptions}
      // menuIsOpen={true}
      noOptionsMessage={() => (
        <div style={{ padding: "40px 0 10px" }}>No results</div>
      )}
      onChange={(value) => {
        navigateToMapEntity(value.id);
      }}
      placeholder={placeholder || searchPlaceholderText}
      styles={styles}
      theme={theme}
      value={null}
    />
  );
};

Search.propTypes = propTypes;
export default Search;
